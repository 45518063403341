<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :searchQuery="{}"
      :searchControl="searchControl"
      :apiName="apiName"
      :buttonShow="false"
      isInline
      :isSelectShow="false"
    >
      <div slot="toolSub" class="m-b-20">
        <div class="flex align-center">
          <div
            class="searchInput el-input-group"
            style="width: 500px; margin: 0 15px 0 0"
          >
            <div class="el-input-group__prepend">起始日期</div>
            <el-date-picker
              v-model="dateArr"
              type="daterange"
              range-separator="至"
              value-format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
            </el-date-picker>
          </div>
          <el-button
            style="margin: 0 15px 0 0"
            type="primary"
            :loading="loading"
            @click="createMerchantSettleAccounts"
            >结算</el-button
          >
          <el-button type="warning" @click="exportExcel" icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
    </app-list>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    const self = this;
    return {
      remote: api.common,
      apiName: "/vip/merchantSettleAccounts",
      formData: { refurbishNum: 0 },
      dateArr: [
        self.$test.formatDate(new Date(), 1),
        self.$test.formatDate(new Date(), 1),
      ],

      dialogVisible: false,
      loading: false,
      searchControl: {
        firstSoltSpan: [0, 0, 0, 0],
        secondSoltSpan: [24, 24, 24, 24],
        thirdSoltSpan: [0, 0, 0, 0],
      },
      props: [
        {
          label: "摊位号",
          prop: "merchantStallNo",
          align: "center",
        },
        {
          label: "商户姓名",
          prop: "merchantPersonnelName",
          align: "center",
        },

        {
          label: "商户结算日期",
          prop: "settleAccountsDate",
          align: "center",
        },
        {
          label: "结算金额",
          prop: "money",
          align: "center",
        },
      ],
      propsFormData: {
        idCard: "",
      },
      vipInfo: {
        name: "",
        cardNo: "",
        status: "",
        money: "",
        returnMoney: "",
      },
    };
  },
  watch: {
    dateArr(e) {
      this.formData.startDate = e[0];
      this.formData.endDate = e[1];
    },
  },
  computed: {},
  created() {},
  methods: {
    async createMerchantSettleAccounts() {
      this.loading = true;
      const { remote, apiName, formData } = this;
      let data = {
        startDate: formData.startDate,
        endDate: formData.endDate,
      };
      let result = await remote.create({ apiName: apiName, ...data });
      this.loading = false;
      this.$message({ message: result.message, type: "success" });
      // console.log(result);
    }, // 导出
    async exportExcel() {
      const { searchQuery } = this;
      await api.common.download({
        apiName: "/vip/merchantSettleAccounts/exportExcel",
        ...searchQuery,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
